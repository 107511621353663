import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import NewsListing from '../components/index/NewsListing';
import ServicesPageWrapper from '../styles/services/ServicesPageStyles.js';
// import Moment from 'react-moment';
import Layout from '../templates/layout';

const Services = ({ path, data }) => {
  const seo = {
    page: 'services',
    title: 'サービス',
    description: "なんとなく形にならないものをWEBサービスへ",
    imgUrl: `${data.pageImg.publicURL}`,
    imgAlt:
      'jdcastro logo, twitter, instagram, facebook, github icons with @9ualia username',
    breadcrumbs: [
      {
        name: 'Services',
        path: '/services',
      },
    ],
  };

  return (
    <Layout seo={seo} path={path}>
      <ServicesPageWrapper>
        <h1>サービス</h1>
        <div className="servicesList">
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <NewsListing key={node.id} data={node} />
          ))}
        </div>
      </ServicesPageWrapper>
    </Layout>
  );
};

Services.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default Services;

export const SERVICES_PAGE_QUERY = graphql`
  query SERVICES_PAGE_QUERY {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "service" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 400)
          html
          timeToRead
          frontmatter {
            date
            title
            slug
            subtitle
            tags
            type
          }
        }
      }
    }

    pageImg: file(relativePath: { eq: "page-meta-img.jpg" }) {
      publicURL # used for SEO
    }
  }
`;
